<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog v-model="modalAction" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6"
            >Cargar masiva de materiales desde archivo excel</span
          >
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12" md="3" sm="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    @click="downloadBaseExcel"
                    id="file-base-massive-upload"
                    v-bind="attrs"
                    v-on="on"
                    class="text-primary"
                    style="font-size: 10px"
                  >
                    <v-icon small class="mx-1" color="primary">
                      mdi-download
                    </v-icon>
                    Descargar Archivo Base Excel
                  </a>
                </template>
                <span
                  >Excel base con el formato requerido para hacer una carga
                  masiva de materiales a la cubicación</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    @click="downloadMasterMaterialsExcel"
                    id="file-master-materials"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="text-primary"
                  >
                    <v-icon small class="mx-1" color="primary">
                      mdi-download
                    </v-icon>
                    <span style="font-size: 10px">
                      Descargar Maestro Materiales Excel
                    </span>
                  </a>
                </template>
                <span
                  >Excel con el listado maestro de todos los materiales
                  (SAP)</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="5" sm="12">
              <section class="ml-2">
                <span style="font-size: 10px;" class="text-danger">
                  IMPORTANTE: Los materiales del archivo base son de ejemplo
                </span>
              </section>
            </v-col>
          </v-row>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  v-model="$v.form.file.$model"
                  :error="$v.form.file.$invalid && submitUpload"
                  label="Archivo (Excel)"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalUploadMaterialsExcelFromCubage"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            :disabled="loading"
            :loading="loading"
            @click="uploadMaterials"
          >
            <small class="text-white">
              Cargar materiales
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalUploadMaterialsExcelFromCubage: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    form: {
      file: { required },
    },
  },
  data() {
    return {
      loading: false,
      submitUpload: false,
      form: {
        file: null,
      },
      codeProject: null,
    };
  },
  mounted() {
    this.codePlace = this.$route.params.codigoLugarInstalacion;
  },
  methods: {
    ...mapActions({
      uploadMaterialsExcelFromCubage: "cubage/uploadMaterialsExcelFromCubage",
      downloadBaseExcelForMassiveUploadMaterials:
        "cubage/downloadBaseExcelForMassiveUploadMaterials",
    }),
    resetForm() {
      this.form = {
        file: null,
      };
      this.submitUpload = false;
    },
    async uploadMaterials() {
      this.submitUpload = true;
      let self = this;
      if (!this.$v.form.$invalid) {
        this.$swal
          .fire({
            icon: "warning",
            title:
              "¿Estas seguro de proceder con la carga masiva de materiales? Ten en cuenta que los materiales actuales serán sobrescritos",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Cargar materiales",
            cancelButtonText: `Cancelar`,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              self.loading = true;
              let payload = {
                codigo_proyecto: self.$route.query.sap_project
                  ? self.$route.query.sap_project
                  : self.codePlace,
                file: self.form.file,
                user: self.user.email,
              };
              const resp = await self.uploadMaterialsExcelFromCubage(payload);
              if (!resp.status || resp.status === 500) {
                swal2.fire({
                  icon: "error",
                  title: "Error",
                  text:
                    "Ha ocurrido un error en la carga masiva, intenta nuevamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
              if (resp.status === 409) {
                swal2.fire({
                  icon: "error",
                  title: "Error",
                  text: resp.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
              if (resp.status === 404) {
                swal2.fire({
                  icon: "error",
                  title: "Error",
                  text: resp.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
              if (resp.status == 200) {
                swal2.fire({
                  icon: "success",
                  title: "Excelente",
                  text: "Materiales cargados correctamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
                self.$emit("materialsUploaded");
                self.closeModalUploadMaterialsExcelFromCubage();
                self.resetForm();
              }
            }
            self.loading = false;
          });
      }
    },
    async downloadBaseExcel() {
      this.loading = true;
      const response = await this.downloadBaseExcelForMassiveUploadMaterials({
        is_sap: this.$route.query.sap_project ? true : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `modelo_carga_materiales_cubicador.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loading = false;
    },
    async downloadMasterMaterialsExcel() {
      this.loading = true;
      let fileLink = document.createElement("a");
      let url = `/maestro_materiales_sap.xlsx`;
      fileLink.href = url;
      fileLink.setAttribute("download", `maestro_materiales_sap.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loading = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style></style>
