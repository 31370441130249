<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="900px"
      @keydown="closeModalReplaceMaterial"
      @click:outside="closeModalReplaceMaterial"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Cambiar a material codificado</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              align-content="center"
              class="my-4 mt-1"
              v-if="currentDocumentMaterial"
            >
              <v-col
                cols="12"
                md="3"
                sm="12"
                v-if="currentDocumentMaterial.custommaterial"
              >
                <section class="text-center">
                  <label for="description" class="font-weight-bold">
                    Descripción
                  </label>
                  <p>
                    {{ currentDocumentMaterial.custommaterial.description }}
                  </p>
                </section>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <section class="text-center">
                  <label for="description" class="font-weight-bold">
                    Cantidad
                  </label>
                  <p>
                    {{ currentDocumentMaterial.quantity }}
                  </p>
                </section>
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="12"
                v-if="currentDocumentMaterial.custommaterial"
              >
                <section class="text-center">
                  <label for="description" class="font-weight-bold">
                    Unidad
                  </label>
                  <p>
                    {{
                      currentDocumentMaterial.custommaterial.measurement.units
                    }}
                  </p>
                </section>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <section class="text-center">
                  <label for="description" class="font-weight-bold">
                    Costo unitario
                  </label>
                  <p>
                    {{
                      `$ ${new Intl.NumberFormat("de-DE").format(
                        parseInt(currentDocumentMaterial.cost)
                      )}`
                    }}
                  </p>
                </section>
              </v-col>
            </v-row>
            <v-data-table
              class="px-3 elevation-1"
              v-model="materialSelected"
              :headers="tableHeaders"
              :items="
                paginatedMaterials.materials ? paginatedMaterials.materials : []
              "
              :items-per-page="10"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingAllMaterials"
              loading-text="Cargando materiales"
              show-select
              single-select
              item-key="code"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    @input="getMaterials(1)"
                    class="w-25"
                    clearable
                    placeholder="Buscar producto"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.favorite="{ item }">
                <v-icon small color="yellow darken-2" v-if="item.favorite">
                  mdi-star
                </v-icon>
                <span v-else>
                  -
                </span>
              </template>
              <template v-slot:item.cu_price="{ item }">
                <span> {{ item.cu_price ? item.cu_price : "$0" }} </span>
              </template>
              <template v-slot:item.stock="{ item }">
                <span> {{ item.stock ? item.stock : 0 }}</span>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="filters.page"
                :length="
                  paginatedMaterials.materialpages
                    ? paginatedMaterials.materialpages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalReplaceMaterial"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            @click="replaceMaterialWithoutCode"
            color="blue darken-3"
            :loading="loading"
            :disabled="!materialSelected.length"
          >
            <small class="text-white">
              Cambiar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    currentDocumentMaterial: {
      default: null,
    },
    closeModalReplaceMaterial: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      materialSelected: [],
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        filter: "",
      },
      tableHeaders: [
        {
          text: "Estandar",
          align: "center",
          sortable: true,
          value: "favorite",
        },
        {
          text: "Stock",
          align: "start",
          sortable: true,
          value: "stock",
        },
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          sortable: true,
          value: "measurement.units",
        },
        {
          text: "CU",
          align: "start",
          sortable: true,
          value: "cu_price",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getAllMaterials: "cubage/getAllMaterials",
      sendReplaceMaterialWithoutCode: "cubage/replaceMaterialWithoutCode",
    }),
    async replaceMaterialWithoutCode() {
      this.loading = true;
      const [materialSelected] = this.materialSelected;
      let payload = {
        docmaterial_id: this.currentDocumentMaterial.id,
        sub_material_code: String(materialSelected.code),
        created_by: this.user.email,
      };
      const resp = await this.sendReplaceMaterialWithoutCode(payload);
      if (resp.status == 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "Material sustituido correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$emit("materialReplaced");
        this.materialSelected = [];
        this.filters.filter = "";
        this.closeModalReplaceMaterial();
      }
      this.loading = false;
    },
    async getMaterials(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        await self.getAllMaterials({
          ...self.filters,
          is_sap: self.$route.query.sap_project ? true : undefined,
        });
      }, self.debounceTime);
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      paginatedMaterials: "cubage/paginatedMaterials",
      currentCubage: "cubage/currentCubage",
      loadingAllMaterials: "cubage/loading",
    }),
  },
  watch: {
    "filters.page": function(page) {
      this.getMaterials(page);
    },
  },
};
</script>

<style></style>
